import React, { useState, useEffect } from "react";
import _ from 'lodash';
import Select from 'react-select';
import gaussian from 'gaussian';
import classnames from 'classnames';

import Login from '../components/dashboard/login';
import Map from '../components/dashboard/map';
import Api from '../lib/Api';
import MinimalLayout from "../components/layout/minimal";
import SEO from "../components/seo";
import styles from '../components/styles/dashboard.module.scss';
import Logo from '../images/logo.png';

const covidStatuses = {
    'positive': 'Positive',
    'negative': 'Negative',
    'not-tested': 'Not Tested',
};

const FeatureListItem = ({ feature, onZoomTo }) => {
    return (
        <li 
            className={classnames(styles.sidebarListItem, { 
                [styles.sidebarListItemPositive]: feature.properties.type === 'covid-tested',
                [styles.sidebarListItemSymptomatic]: feature.properties.type === 'symptomatic',
            })} 
            onClick={() => onZoomTo(...feature.geometry.coordinates)}
        >
            <span>{feature.geometry.coordinates[1]}, {feature.geometry.coordinates[0]}</span><br />
            <span className="text-muted">{feature.properties.date}</span><br />
            <div>
                <span 
                    className={classnames('badge', 'ml-1', { 
                        [styles.badgePositive]: feature.properties.type === 'covid-tested',
                        [styles.badgeSymptomatic]: feature.properties.type === 'symptomatic',
                        [styles.badgeUnknown]: feature.properties.type === 'unknown',
                    })}
                >{feature.properties.status}</span>
            </div>
        </li>
    );
};

const CitizenDashboardPage = () => {
    const [token, setToken] = useState(null);

    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState(null);
    const [geoData, setGeoData] = useState(null);
    const [mapViewport, setMapViewport] = useState({
        latitude: 39.82,
        longitude: -98.52,
        zoom: 4,
    });

    const getUsers = async () => {
        try {
            const users = await Api.getCitizenDashboardUsers(token);
            const usersOptions = users.map(user => ({
                value: user.user_id,
                label: user.user_id,
                meta: {
                    covid_status: user.covid_status ? covidStatuses[user.covid_status] : 'Unknown',
                    ..._.omit(user, ['covid_status', 'user_id']),
                },
            }));

            setUsers(usersOptions);
            if (usersOptions.length > 0) {
                setUserId(usersOptions[0].value);
            }
        } catch (err) {
            if (err.statusCode === 403) {
                setToken(null);
            }

            window.alert(err.message);
        }
    };

    const getDataForUser = async () => {
        const { collection } = await Api.getCitizenDashboardData(userId, token);
        setGeoData(collection);

        // Initialize map zoom for user
        if (collection.features.length > 0) {
            const firstFeature = collection.features[0];
            setMapViewport({
                ...mapViewport,
                longitude: firstFeature.geometry.coordinates[0],
                latitude: firstFeature.geometry.coordinates[1],
                zoom: 9,
            });
        }
    };

    useEffect(() => {
        if (token) {
            getUsers();
        }
    }, [token]);

    useEffect(() => {
        if (userId) {
            getDataForUser();
        }
    }, [userId]);

    const handleFeatureZoom = (longitude, latitude) => {
        setMapViewport({ 
            ...mapViewport,
            longitude,
            latitude,
            zoom: 14,
        });
    };

    const currentUser = users.find(user => user.value === userId);

    return (
        <MinimalLayout>
            <SEO title="Citizen Dashboard" />
            <main className={styles.main}>
                {token ? (
                    <div className={styles.pageWrapper}>
                        <div className={styles.sidebar}>
                            <img src={Logo} alt="Covidseeker" />

                            <Select 
                                options={users}
                                value={currentUser || null}
                                onChange={option => setUserId(option.value)}
                                className="react-select"
                            />

                            <ul className={styles.sidebarList}>
                                {currentUser &&
                                    <div className="border-bottom p-2">
                                        <table className="table-sm w-100">
                                            <tbody>
                                                <tr>
                                                    <th scope="col">COVID Status</th>
                                                    <td className={currentUser.meta.tested_last_week === 1 ? 'table-danger' : 'table-secondary'}>
                                                        {currentUser.meta.tested_last_week === 1 ? 'Positive' : 'Unknown'}
                                                    </td>
                                                </tr>
                                                {currentUser.meta.datetime &&
                                                    <tr>
                                                        <th scope="col">Week Tested</th>
                                                        <td>{currentUser.meta.datetime}</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                }

                                {geoData && (
                                    geoData.features.length > 0 ? (
                                        geoData.features.map((feature, i) => 
                                            <FeatureListItem key={i} feature={feature} onZoomTo={handleFeatureZoom} />
                                        )
                                    ) : (
                                        <p className="p-3">No data to display.</p>
                                    )
                                )}
                            </ul>
                        </div>
                        <div className={styles.mapWrapper}>
                            <Map 
                                geoData={geoData} 
                                viewport={mapViewport} 
                                onViewportChange={setMapViewport} 
                            />
                        </div>
                    </div>
                ) : (
                    <Login setToken={token => setToken(token)} />
                )}
            </main>
        </MinimalLayout>
    );
};

export default CitizenDashboardPage;
